import React from 'react'
import './Form.css'
import {BiSend} from 'react-icons/bi'
import { useRef, useState, useEffect} from 'react';
import emailjs from 'emailjs-com';
import {toast} from "react-toastify";




const Form = () => {

  const[sent, setSent] = useState('')
  const form = useRef()
  const [input, setInput] = useState({
    name:'',
    email:'',
    message:''
  })



    const fillDetails=(e)=>{
          const inputName=e.target.name;
          const inputValue=e.target.value;
          setInput({...input,[inputName]:inputValue})
      }



  const sendEmail = (e) => {
    e.preventDefault();
    if(input.name && input.email && input.message){
        setInput({
            name:'',
            email:'',
            message:''
          })
    emailjs.sendForm('service_oq9ubr8', 'template_8pxgwx8', form.current, 'NKZ6rJ2TPd2TYecM3')
      .then((result) => {
          setSent(true)
          toast('Message Sent')
      }, (error) => {
          setSent(false)
          toast.error('Network Issue')

      })}
      else{
        toast.error('Please fill the required fields')
      }
  };



  useEffect(()=>{
    if (!sent===''){
      setTimeout(()=>{
        setSent((prev)=>{
          return ''
        })
      },8000)
    }
  })




  return (
    <div className='Form_Container'>
        <h2 className="heading">Pitch Us</h2>
        <form action="" ref={form}>
        <p>Hello, my name is <input name="name" type="text" value={input.name} onChange={fillDetails} required /> my E-mail address is <input type="email" name='email' value={input.email} onChange={fillDetails} required /> and I would like to discuss about <input name="message" type="text" value={input.message} onChange={fillDetails} required /></p>
        <div>
        <button type='submit' className='btn btn_primary contact_btn'  onClick={sendEmail}><BiSend style={{ fontSize: '1.5rem'}}/></button>
        </div>
        </form>
    </div>

  )
}

export default Form