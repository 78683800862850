import './Home.css';
import About from '../../Components/About/About';
import ForiBanner from '../../Components/ForiBanner/ForiBanner';
import Mission from '../../Components/Mission/Mission';
import API from '../../Components/API/API'
import Products from '../../Components/products/products'
import Services from '../../Components/services/services'
import ImageComp from '../../Components/ImageComp/ImageComp';
import OurPartners from '../../Components/OurParteners/OurPartners'
import Partners from '../../Components/Partners/Partners'
// import Contact from '../../Components/Contact/Contact'
import MobileNav from '../../Components/MobileNav/MobileNav'
import ContactUs from '../../Components/Contact_Us/ContactUs';

function Home() {
  return (
    <div className="Home">
          <MobileNav/>
          <ForiBanner/>
          <About/>
          <Mission/>
          <ImageComp sectionName = 'one'/>
          <API/>
          <Products/>
          <ImageComp sectionName = 'two'/>
          <Services/>
          <Partners/>
          <OurPartners/>
          <ContactUs/>
    </div>
  );
}

export default Home;
