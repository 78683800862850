import React from 'react'
import './Mission.css'
import CEO from '../../assets/ceo.png'

const Mission = () => {
  return (
    <div className="Mission">
        <div className='Mission_img'>
          <img src={CEO} alt="" />
        </div>
        <div className='Mission_heading'>
        <p>Our Mission</p>
        <p>To Provide Shared Global Digital Economy, with a laser-like focus on environmental issues and unprecedented value creation for our planet and its people</p> 
        </div>
    </div>
  )
}

export default Mission