import './App.css';
import Home from './Pages/Home/Home';
import ImpactApi from './Pages/ImpactApi/ImpactApi';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <Router>
    <div className='app'>
      <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/impact-Calculation" element={<ImpactApi/>}/>
      </Routes>
      <ToastContainer/>
    </div>

    </Router>
  )

}

export default App
