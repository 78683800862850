import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer,Legend } from 'recharts';
import "./linechart.css";


export default class Example extends PureComponent {
  

  render() {
    return (
        <div className="linegraphresponsive" >
      <ResponsiveContainer >
        <AreaChart
        title="Chart of PU x UV"
        className='areachart'
          data={this.props.data}
      
        >
          
          <XAxis dataKey="name" stroke='white' />
          <YAxis 
          type='number'
          tick={{ fontSize: '10px' }}
          stroke='white'
          tickCount={3}
          // domain={[0, dataMax => Math.ceil(Number(dataMax) / 10000) * 10000]}
          // tickFormatter={tick => `${Math.floor(tick).toLocaleString('pt-BR')}`}
          tickMargin={10}
          unit=' kg'
              // label={{ value: 'carbondioxide in kg', angle: -90, position: 'insideLeft' ,textAnchor: 'middle' ,stroke:"transparent" ,strokeWidth:1}}
               /> 
          <Tooltip />
          <Legend/>
          <Area type="line" dataKey="Carbon_Emissions_Saved_Yearly" stroke="#c177f5" strokeWidth={2}  fill="rgba(193, 119, 245,0.2)" dot={{r:3,fill:"black"}} />
        </AreaChart>
      </ResponsiveContainer>
      
      </div>

    );
  }
}

