import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide} from "swiper/react";
import {MdEmail} from 'react-icons/md'
import {BsArrowRightShort} from 'react-icons/bs'

// Import Swiper styles
import "swiper/css";
import './API.css'

import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Parallax, Pagination, Navigation } from "swiper";

// import "./styles.css";

// import required modules


const Api= () => {


  return (
    <section id='api' className='Api_section'>

      <Swiper

        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        // initialSlide={1}
        
        speed={700}
        parallax={true}
        // pagination={{
        //   clickable: true,
        // }}
        pagination={{
          type: "progressbar"
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation]}
        className="mySwiper"

        
      >
        <div
          slot="container-start"
          className="parallax-bg slide_bg"
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide >
          <div className="text_controller">
          <div className="title" >
            <h4>FORI devotes all of its efforts to provide a simpler path towards <span style={{color:'#8DB600'}}>Greener</span> Planet</h4>
          </div>
          <div className="text">
          <p>
              The planet is changing rapidly and we are the last generation who can do anything about it. Lets partner up to make a revolutionary change !
          </p>
            <p className="slider_span learn_more">Learn more <BsArrowRightShort style={{color:'8DB600' ,fontSize:'2.5rem'}}/></p>
          </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="text_controller">
        <div className="title" >
            <h4>FORI made calculating Carbon Footprint for industries and indviuals as easy as 2x2</h4>
          </div>
          <div className="text">
            <p>
            FORI provides smart solutions with which our clients can easily track their carbon emissions and their performance just by plug and play.
            </p>

          </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="text_controller">
        <div className="title" >
        <h4> Excited ? We are just a call away ! </h4>

          </div>
          <div className="text">
        <p className="slider_span lets_talk">Let's Talk <a href="#contact"><MdEmail className="lets_talk_icon"/></a></p>
        <p>You can also see the our products here</p>
          <a href="#products"><button className="btn_API">View Products</button></a>
          </div>
          </div>
        </SwiperSlide>
      </Swiper>
</section>
  )
}

export default Api