import React from 'react'
import './Navbar.css' ;


const Navbar = () => {
  return (
    <div className="Navbar">
        <div className="Navbar_left">
            <img src={require('../../assets/Final-Logo.png')} alt="" />
        </div>
        <div className="Navbar_right">
            <a href='#about'>About</a>
            <a href='#products'>Products</a>
            <a href='#services'>Services</a>
            <a href='#contact'>Contact</a>
        </div>
    </div>
  )
}

export default Navbar