import React,{useEffect,useState} from 'react';
import {toast} from "react-toastify";
import axios from "axios";
import Linegraph from './components/linechart/linegraph_bottom';
import PieChart from "./components/piechart/piechart";
import BarChart from "./components/barchart/barchart"
import {BsArrowDown} from 'react-icons/bs'
import {AiOutlineCopyrightCircle} from 'react-icons/ai'

import ScrollProgress from '../../Components/ScrollProgress/ScrollProgress'
import {RiLinkedinLine} from 'react-icons/ri'
import {RiTwitterLine} from 'react-icons/ri'
import {IoLogoInstagram} from 'react-icons/io'
// import bgvideo from '../../assets/calc_bg.mp4'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import './ImpactApi.css'


var perYearsaving;

function ImpactApi() {
AOS.init({
  offset: 300, 
  delay: 100
}
);

    const [renderGraph,setrenderGraph]=useState(false);
    const [notify,setNotify]=useState({selectCountry:false,kmsValue:false});
    const[ perDaysaving,setPerdaysaving]=useState(0);
    const countries=[{country:"Select Your Country"},{country:"Norway"},{country:"Pakistan"},{country:"China"}]
    const[userinput,setUserinput]=useState({country:"Select Your Country",kms:""});
    const [dataY,setDataY] =useState([])
    const [piedata, setpiedata] =useState([])
    const [tressData,setTressData] = useState(0)
    const [fuelSaved,setFuelsaved]=useState(0)
    const [Spinner,setSpinner]=useState(false);



    const [size, setSize] = useState(window.innerWidth<=350?"13rem":window.innerWidth<=500?"15rem":window.innerWidth<=1300 && window.innerWidth>500? '30rem':"35rem")

    // function for collecting user's data to send to backend
    function userData(e){
        const inputName=e.target.name;
        const input=e.target.value;
        setUserinput({...userinput,[inputName]:input})
}

    const sizeSetterFunction =() =>{
      if(window.innerWidth<=500){
        setSize((prev)=>{
          return "18rem"
        })
      }else  if(window.innerWidth<=1300 && window.innerWidth>500){
         setSize((prev)=>{
          return "30rem"
        })
      }
      else if(window.innerWidth>1300){
         setSize((prev)=>{
          return "35rem"
        })
      }
    }


      // ..............................................................user form submiision...................
      function submit(e){
        setrenderGraph(false)
        e.preventDefault();
        if(userinput.country==="Select Your Country" || userinput.kms===""){
          setrenderGraph(false);
          toast('Required Field')
          if(userinput.country==="Select Your Country"){
           return  userinput.kms===""?setNotify({selectCountry:true,kmsValue:true}):setNotify({selectCountry:true,kmsValue:false})
            }else{
             return  userinput.country==="Select Your Country"?setNotify({selectCountry:true,kmsValue:true}):setNotify({selectCountry:false,kmsValue:true})
        }
        }else{
          setNotify({selectCountry:false,kmsValue:false});
          setSpinner(true)


        axios({
          method: "POST",
          url: "https://backend.fori.co/api/calculation",
          data: { UserInput: { name:userinput.country, KmsShared:userinput.kms} }
        }).then(response => {
          setSpinner(false)
          setrenderGraph(true)
          setPerdaysaving(response.data.data.perDaySaving)
           setpiedata([
            { name: 'Petrol Cars %', value: response.data.data.p, color:"#c177f5"},
            { name: 'Diesel Cars %', value: response.data.data.p, color:"#fe6df7"},
            { name: 'Hybrid cars %', value: response.data.data.h, color:"#ee817f"}
          ])
          setTressData(response.data.data.TressSaved)
          setFuelsaved(response.data.data.FuelSavedperkm)


  }).catch(err => {
    setSpinner(false)
    setrenderGraph(false)
    toast.error(err.message,{position:"top-right"}) });}

    }



    useEffect(()=>{

      if(Spinner===true){
      document.getElementById("Spinner").scrollIntoView();
      }
      if(renderGraph===true){
      document.getElementById("graphs").scrollIntoView();
      }
    },[Spinner,renderGraph])
    // .............................................calculation..............................................

    useEffect(()=>{
      perYearsaving=perDaysaving*365;
      const [year1,year2,year3,year4,year5]=[Math.round(perYearsaving),Math.round(perYearsaving*2),Math.round(perYearsaving*3),Math.round(perYearsaving*4),Math.round(perYearsaving*5)]
      setDataY( [
          {name: 'Year 1',Carbon_Emissions_Saved_Yearly:year1},
          {name: 'Year 2',Carbon_Emissions_Saved_Yearly:year2},
          {name: 'Year 3',Carbon_Emissions_Saved_Yearly:year3},
          {name: 'Year 4',Carbon_Emissions_Saved_Yearly: year4},
          {name: 'Year 5',Carbon_Emissions_Saved_Yearly: year5}
        ])


    },[perDaysaving])

    useEffect(()=>{

      window.addEventListener('resize', function(){
        sizeSetterFunction()
      }, true);
    // window.removeEventListener('resize', function(){
    //   sizeSetterFunction()
    // },true);

    })
  // ..........................................piechart data........................................................






    return (
        <div style={{position:'relative' , background:'#282b35'}}>
    <div id='calculation_itro' className='Api_section_calc' >
        <div className="TextBox card_layout" data-aos="zoom-in">
                <h1>Calculate Impact of Electric Cars</h1>

                <p>The Fori team of sustainability specialists carried out a comprehensive study to come up with an easy and accessible concept to measure the impacts of EV sharing. Our tech is accessible to anyone who wants to contribute towards the reduction of there carbon footprint. Our tech is based on a comprehensive set of indicators. These measurements of EV impacts decision making both a personal and policy levels. </p>
                <div>
                  <a href='#calculation' className="downArrow" ><BsArrowDown /></a>
                </div>
           </div>
           <Tendots/>


           {/* .............................................................form for user input....................................... */}
        <form className='form card_layout' onSubmit={submit} data-aos="zoom-in">

            <div className="Selection_div">
            <div className="countryselector">
                <h4>Select Your Region</h4>
                
                <select name="country" id="country" autofous={0} onChange={userData}>
                    {countries.map((obj,index)=>{
                        return(<option key={index} value={obj.country} className="country_option">{obj.country}</option>)
                    })}
                </select>
          </div>
          <div className="kmsshared">
              <h4>Enter Daily Shared Kms! </h4>
              <input type="number" inputMode="decimal" step="any" name="kms" id="kms" placeholder={notify.kmsValue?"*required feild":null} value={userinput.kms} onChange={userData} />
          </div>
          </div>
          <button type="submit" className='btn btn_primary calculate_btn'>Calculate Your Impact</button>
        </form>
        
        {/* ........................................Graphs.............................. */}
        {Spinner?<div className='Spinner' id="Spinner"><div className='rotater'></div></div>:null}

        {renderGraph && !Spinner?
          <div className='graphs__container'>
            <Tendots/>

            {/* ....................................first graph........................... */}
                <div className='graphs__container__cards' id="graphs" data-aos="zoom-in">
                    <div className='paperCard'>
                    <div className='graphs__container__firstcard'>
                    <div className='graphs__container__fcard_header'>
                        <h2 className='fcard_heading'>Congratulation 
                        <span role="img" aria-label="congrats">🎉</span></h2>
                        <p>Your are helping us in saving the planet by promoting ride sharing </p>
                        <h5>Your contribution towards the green planet with this ride is</h5>
                    </div>
                    <div className='graphs__container__fcard'>
                      <div className='graphs__container__fcard__childs'>{tressData} trees are relaxed</div>
                      <div className='graphs__container__fcard__childs'>{Number (perDaysaving*1000).toFixed(2)} gram of Carbondioxide saved</div>
                      <div className='graphs__container__fcard__childs'>{Number(fuelSaved).toFixed(2)} liter of fuel saved</div>
                    </div>
                    </div>
                    </div>
                      <p className='graph_para'>By this specific ride you have helped the plannet by relaxing {tressData} trees. you have saved {Number (perDaysaving*1000).toFixed(2)} grams of Carbondioxide saved along with {Number(fuelSaved).toFixed(2)} liter of fuel.</p>
                </div>
                <Tendots/>

            {/* ....................................2nd graph (piechart).................................. */}
                <div className='graphs__container__cards'>
                <div className='paperCard'>
                    <Linegraph data={dataY}/>
                    </div>
                      <p className='graph_para'>If you share {userinput.kms} kms each day, this is the trend analysis of the saved Carbon Dioxide in 5 years </p>
                </div>

                <Tendots/>


            {/* ......................................3rd graph................................... */}
                <div className='graphs__container__cards'>
                <div className='paperCard'>

                      <BarChart data={tressData}/>
                      </div>
                      <p className='graph_para'>By each shared ride you are contributing to a greener planet by removing the stress from the trees.</p>
                </div>
                <Tendots/>


            {/* ..........................................4rd graph................................... */}
                <div className='graphs__container__cards'>
                <div className='paperCard'>

                    <PieChart data={piedata} legend={true} styless={{width:"100%",height: size }} innerRadius={"45%"} outerRadius={"65%"} cornerRadius={3}/>
                    </div>
                      <p className='graph_para'>Each shared ride reduces a specific number of vehicles from each catagory which depends upon the location of shared ride.</p>
                </div>
            {/*...........................................4rd graph end here.............................  */}
          </div>


        :null}
        {/* ....................................................................................... */}

        <div className='footer_calc'>
            <AiOutlineCopyrightCircle/><p>All Rights Reserved by FORI.co</p>        
        </div>

        <div className='socials'>
            <div>
                <a href="https://www.linkedin.com/company/foriinc/mycompany/" target='_blank' rel="noreferrer" className="downArrow" ><RiLinkedinLine /></a>
            </div>
            <div>
                <a href="https://twitter.com/ForiInc?t=lQe8e6zbps3rXYu2K4TYvA&s=08" target='_blank' rel="noreferrer" className="downArrow"><RiTwitterLine/></a>
            </div>
            <div>
                <a href="https://www.instagram.com/fori_inc/?igshid=YmMyMTA2M2Y=" target='_blank' rel="noreferrer" className="downArrow"><IoLogoInstagram /></a>
            </div>
            </div>

        <ScrollProgress/>

    </div>
    </div>
  )
}

export default ImpactApi;



const Tendots =()=>{

  return(
    <div id='calculation' className='dots'>
    <div></div>
    </div>

  )
}