import React from 'react';
import './OurPartners.css';
import {partners} from "../../data/AppData";



const OurPartners= () => {
  return (
    <section id='about' className='Partner_section'>
    <div className="Partners_flex">
        <div className='products_heading'>
        <h2>Our Powerfull Partners</h2>
        <p>Fori enjoys strong relationships with leading names in the tech world and beyond.</p>
        </div>
    
        <div className='Partners-Cards-Parent'>
          {partners.map((partner)=>{
            return(
              <div className='Partner-card flex_row' key={partner.id}>
                <img src={require(`../../assets/${partner.logo}`)} alt="partner.logo" />
              </div>
            )
          })}
        </div>
    </div>
</section>
  )
}

export default OurPartners