import React from 'react'
import './ContactUs.css'
import Map from '../../Components/Contact_Us/Contact_Components/Map/Map';
import Info from '../../Components/Contact_Us/Contact_Components/Info/Info';
import Form from './Contact_Components/Form/Form';


const ContactUs = () => {
  return (
    <>
    <div id='contact' className='ContactUs'>
        <Map/>
        <Info/>
        <Form/>
    </div>
    
    
    <div className='copyWrite'>
          <p className='para'>© 2021 FORI inc</p>
    </div>
    </>

  
  )
}

export default ContactUs