import React from 'react'
import './services.css'
import {ServicesData} from "../../data/AppData";

const Services= () => {
  return (
    <section id='services' className='Services_section'>
    <div className=" services_section_controller">
      <div className='Services-main-div'>
        <div className='headerServices'>
        <h2 className='heading'>Services & Smart Solutions</h2>
        <p className='para'>FORI's intelligent technology will assist in the resolution of problems for both individuals and businesses.</p>
        </div>
        <div className='Services-Cards-parent'>
      {ServicesData.map((card)=>{
        return(
          <article className='Services-card' key={card.id}>
          <div className='Services_card-img'>
          <img src={require(`../../assets/${card.img}`)} alt='services'/>
          </div>
          <div className='Services-Card-descrp'>
          <h4>{card.h}</h4>
          <p>{card.p}</p>
          </div>
          </article>
        )
      })}
      </div>
      </div>
      </div>
</section>
  )
}

export default Services