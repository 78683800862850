import React from 'react'

const Map = () => {
  return (
    <div>
        <div style={{width: "100%", boxSizing:'border-box'}}><iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title='Fori.inc Location' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=543%20Center%20Dr,%20Palo+(Fori.co)&amp;t=p&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a></iframe></div>
    </div>
  )
}

export default Map