import React, { useState } from 'react';
import './products.css'
import {BiRightArrowAlt, BiLeftArrowAlt} from 'react-icons/bi'
import { data } from '../../data/AppData'
import img2  from '../../assets/01.png'
import img1  from '../../assets/02.png'
import img3  from '../../assets/03.png'
import img4  from '../../assets/04.png'

//  Functions to change product


const Products= () => {

  const [id, setId] = useState(1)

  const nextSlide = ()=>{
    if(id<data.length){
      setId((prev)=>{
        return prev+1
      })
    }


  }
  const previousSlide = ()=>{
    if(id>1){

      setId((prev)=>{

        return prev-1
      })
    }
  }

  const project = data.find((item)=>{
    return item.id ===id
  })

  return (
    <section id='products' className='Products_section'>
      <div className='Products_Banners'>
        <a href={project.link} target='_blank' rel="noreferrer" >
        <div className='product_image'>
          <img src={id===1?img1:id===2?img2:id===3?img3:id===4?img4:null} alt="" />
        </div>
        </a>
        <div className='product_description_Container' >
          <div className="product_description_card">
            <h2 className='heading' >{project.name}</h2>
            <p className='para'>{project.description}</p>
          <div className='button_parent'>
          <a href={project.link} target='_blank' rel="noreferrer" >
            <button className='View-Demo'>View Demo</button>
          </a>
            <div className="project_buttons_container">
              <button className={id===1?'deactive': 'project_button'}  onClick={previousSlide}><BiLeftArrowAlt className='btn_icon'/></button>
              <button className={id===data.length?'deactive': 'project_button'}  onClick={nextSlide}><BiRightArrowAlt className='btn_icon'/></button>
            </div>
          </div>
          </div>
        </div>
      </div>
</section>
  )
}

export default Products