import React from 'react'
import './ImageComp.css'
import sectionOneBg from '../../assets/bg.jpg'
import sectionTwoBg from '../../assets/crowd.jpg'
const ImageComp = ({sectionName}) => {
  return (
    <div className='ImageComp' style={{backgroundImage:sectionName==='one'?`url(${sectionOneBg})`:`url(${sectionTwoBg})`}}></div>
  )
}

export default ImageComp 