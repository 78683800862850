import React from 'react'
import './About.css'

const About = () => {
  return (
    <div id='about' className="About">
        <div className='About_heading'>
        <p>About Us</p>
        <p>We accelerates startup by rapid designing, development and deployment of end-end technologies for impact focused startups</p> 
        </div>
    </div>
  )
}

export default About