import React from 'react'
import './Info.css'
import {BiPhone} from 'react-icons/bi'
import {BiMap} from 'react-icons/bi'
import {MdOutlineAlternateEmail} from 'react-icons/md'

const Info = () => {
  return (
    <div className='info_parent_container'>
        <h2 className="heading">Meet Us</h2>
        <div className="Info_container">
            <div className="Info_child">
                <BiPhone/>
                <a href="tel:+4740717345" className="para">+47 407 17 345</a>
            </div>
            <div className="Info_child">
                <MdOutlineAlternateEmail/>
                <a href='mailto:info@fori.co' className='para'>info@fori.co</a>
            </div>
            <div className="Info_child">
                <BiMap/>
                <a href='https://www.google.com/maps/dir//543+Center+Dr+Palo+Alto,+CA+94301+USA/@37.4557507,-122.1432251,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808fbb6d26e1c0f7:0x90263614de6fe66f!2m2!1d-122.1432251!2d37.4557507' target='_blank'  rel="noreferrer">543 Center Dr, Palo Alto, CA 94301, USA</a>
            </div>
        </div>
    </div>
  )
}

export default Info