import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './ForiBanner.css'
import { Pagination , Autoplay , Navigation} from "swiper";
import Navbar from "../Navbar/Navbar";



const ForiBanner = () => {
  return (


    <div className="ForiBanner">
    <Navbar/>
      <Swiper
        autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
        pagination={{
          dynamicBullets: true,
          clickable: true
        }}
        navigation={false}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
      >

        <SwiperSlide>
            <div className="slide1">
                <h1 className="banner_slide">Where Data and Creativity Comes Together!</h1>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slide2">
                <h1 className="banner_slide">Empowering Ride Sharing</h1>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slide3">
                <h1 className="banner_slide">Striving for Safer and a Better World</h1>
            </div>
        </SwiperSlide>

      </Swiper>
      </div>
  


  )
}

export default ForiBanner