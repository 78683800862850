import React from 'react'
import './Partners.css'



const About= () => {
  return (
    <section id='about' className='partner_section'>

    <div className="partner_Banner">
      <h2 className='heading'>Meet our Team of Experts</h2>
      <div className='partner_cards_div'>

        <div className='partner_card'>
          <div className='card_Image'>
            <img src={require('../../assets/ceo.png')} alt="" />
          </div>
          <div className='card_description'>
              <h5>Dr. Musstanser Tinauli</h5>
              <h6>Company CEO</h6>
          </div>
        </div>

        <div className='partner_card'>
          <div className='card_Image'>
            <img src={require('../../assets/barry.jpeg')} alt="" />
          </div>
          <div className='card_description'>
          <h5>Barry Flew</h5>
          <h6>Advisor</h6>
        </div>
        </div>

        <div className='partner_card'>
          <div className='card_Image'>
            <img src={require('../../assets/winifred.jpeg')} alt="" />
          </div>
          <div className='card_description'>
          <h5>Winifred Patricia</h5>
          <h6>Advisor</h6>
        </div>
        </div>

      </div>
    </div>
</section>
  )
}

export default About